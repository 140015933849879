import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'



import BottomFooter from "../../components/site/template/Footer/Footer";
import TopWrapper from "../../components/site/template/TopWrapper/TopWrapper";
import SiteHome from "../../container/site/Home/Home";
import About from "../../container/site/About/About";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import Languages from "../../components/site/template/TopWrapper/Languages";

const LayoutSite = () => {
    return (


        <div className="page_wrapper">


            <div id="preloader " className='d-none'>

                <div className="circle-border">
                    <div className="circle-core"></div>
                </div>
            </div>
            <Router>
           <Wrapper>
           <TopWrapper />
           

           <Routes>
          
               <Route path="/" element={<SiteHome/>} />
               <Route path="/about" element={<About/>} />
               <Route path="/admin/languages" element={<Languages/>} />

           </Routes>
           </Wrapper>
           


            <BottomFooter />

            </Router>



        </div>


    )
}

export default LayoutSite