import * as actionTypes from './actions'

import Utils from "../modules/utils";
import API from '../modules/api'

const inistialState = {
  path: "/",
  lang: (window.localStorage.getItem("lang")?window.localStorage.getItem("lang"):"fa"),
  tranlates:[],
  tranlatesArr:[],
  result: [],
  is_login:false,
}
  const reducer =  (state = inistialState, action) => {
    switch (action.type) {
      case actionTypes.CHANGEVIEW:
        return {
          ...state,
          path: action.value,
        }
      case actionTypes.CHANGELOGIN:
        return {
          ...state,
          is_login: action.value,
        }
      case actionTypes.CHANGETRANLATEARR:
        return {
          ...state,
          tranlatesArr: action.value,
        }
      case actionTypes.CHANGELANG:
    const temp = (new Utils()).getTranslates(action.value).then(result=>{
      console.log("tranlates2",result.data)
    return result;
    });
    console.log("Lang3",action.value);
    console.log("Lang4",temp)
   // console.log("tranlates2",temp)
        return {
          ...state,
          lang: action.value,
          tranlates:temp
        }
      case actionTypes.CHANGETRANLATEARR:
    const temp2 = (new Utils()).getTranslates(state.lang).then(result=>{
      console.log("tranlates2222",result)
      return {
        ...state,
        tranlates:result
      }
   // return result;
    });
   

      default:
        return state
    }
  }
  
  export default reducer
  