import React from "react";
import Wrapper from "../../../../hoc/Wrapper/Wrapper";
import SubNavItem from "./SubNavItem";
import RouteLink from "../../../Routing/RouteLink";

const NavItem = (props) => {

    let   childs=null
    let haschild_class="nav-item"
    if(props.item.children && props.item.children.length>0)
    {

        haschild_class="nav-item has_dropdown"
        childs= props.item.children.map((item, index) => {
            return <SubNavItem item={item}  key={index} /> 
            })

    }
   
    return (
        
        <li className={haschild_class}>
         <RouteLink href={props.item.path} click={props.click} className="nav-link" >{props.item.title}</RouteLink>
          
            {
                
                childs?<Wrapper>
                    <span className="drp_btn"><i className="icofont-rounded-down"></i></span>
                    <div className="sub_menu">
                    <ul>
                    {childs}
                    </ul>
                  </div>
                   
                    </Wrapper>:null

            }
        </li>


    )

}


export default NavItem