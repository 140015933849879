import React from 'react'

function BottomFooter({netWorksState}) {
  return (
    <div className="bottom_footer">

    <div className="container">

        <div className="row">
            <div className="col-md-4">
                <p>© Copyrights 2023. All rights reserved.</p>
            </div>
            <div className="col-md-4">
                <ul className="social_media">
                {
                                        netWorksState.map((item) => {
                                            return (item.isVisible ? <li><a href={item.link}><i className={item.icon}></i></a></li>
                                                : null
                                            )
                                        })

                                    }
                
                </ul>
            </div>
            <div className="col-md-4">
                <p className="developer_text">Design &amp; developed by <a href="https://themeforest.net/user/kalanidhithemes" target="blank">Kalanidhi Themes</a></p>
            </div>
        </div>

    </div>

</div>

  )
}

export default BottomFooter