export const addChangeView = (mpath) =>  (dispatch, getState) => {
  
    dispatch({
      type: 'CHANGEVIEW',
      value: mpath
    })
}
export const addChangeLang = (mlang) =>  (dispatch, getState) => {
  console.log("Lang2",mlang);
    dispatch({
      type: 'CHANGELANG',
      value: mlang
    })
}
export const addChangeTranslateArr = (val) =>  (dispatch, getState) => {
  
    dispatch({
      type: 'CHANGETRANLATEARR',
      value: val
    })
}

export const addChangeLlogin = (val) =>  (dispatch, getState) => {
  
  dispatch({
    type: 'CHANGELOGIN',
    value: val
  })
}