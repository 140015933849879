import React, { useEffect, useState, useRef } from 'react'
import API from "../../../../modules/api";
import * as appConsts from '../../../../store/consts'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addChangeView, addChangeLang, addChangeTranslateArr } from '../../../../store/pathActions'
import Util from "../../../../modules/utils";
import './HomeUniqFeatures.css'
function HomeUniqFeatures() {
    const utl = new Util();

    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates)
    const [showElement, setShowElement] = useState(true);
    const [homeFeatureState, setHomeFeatureState] = useState(
        {
            icon1_url: "./assets/images/features_icon_01.svg",
            icon2_url: "./assets/images/features_icon_02.svg",
            icon3_url: "./assets/images/features_icon_03.svg",

            title: "Unique features",
            pl1: "Lorem Ipsum is simply dummy text of the printing and typese tting indus",
            pl2: "orem Ipsum has beenthe standard dummy text ever since",
            title1: "Highly customizable",
            dsc1: "Lorem Ipsum is simply dummy text ofthe printing and type setting indus ideas orem Ipsum has beenthe",
            linktext1: "DISCOVER MORE ",
            link1: "/",
            title2: "Highly customizable",
            dsc2: "Lorem Ipsum is simply dummy text ofthe printing and type setting indus ideas orem Ipsum has beenthe",
            linktext2: "DISCOVER MORE ",
            link2: "/",
            title3: "Highly customizable",
            dsc3: "Lorem Ipsum is simply dummy text ofthe printing and type setting indus ideas orem Ipsum has beenthe",
            linktext3: "DISCOVER MORE ",
            link3: "/",

        }
    );
    useEffect(() => {
       
        loadData();

    }, [])
    useEffect(() => {
       
        loadData();

    }, [lang,tranlates])


    const loadData=()=>{

        let arrNames = ['home-feature-unique-show'];
        let arrImges = [];
        let arrTexts = [];
        let arrStatic = ['home-feature-unique-title', 'home-feature-unique-pl1', 'home-feature-unique-pl2'];
        arrNames = [...arrNames, ...arrStatic];
        for (let index = 1; index <= 3; index++) {
            arrNames = [...arrNames, `home-feature-unique-icon${index}-attach-id`];
            arrImges = [...arrImges, `home-feature-unique-icon${index}-attach-id`];
            const arr = ['title', 'dsc', 'linktext', 'link'];
            for (let index2 = 0; index2 < arr.length; index2++) {
                arrNames = [...arrNames, `home-feature-unique-${arr[index2]}${index}`];
                arrTexts = [...arrTexts, `home-feature-unique-${arr[index2]}${index}`]

            }

        }
        let obj = {};
    try {
        tranlates.then(result2 => {
            (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                console.log("TRE", result)
                if (result['home-feature-unique-show'] != null) {
                    setShowElement((result['home-feature-unique-show'] == 'true'))
                }
                for (let index = 0; index < arrImges.length; index++) {
                    const element = arrImges[index];
                    const elementName = element.replace('home-feature-unique-', '').replace('-attach-id');
                    if (result[element] != null)
                        obj[`icon${index + 1}_url`] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;

                }
                for (let index = 0; index < arrTexts.length; index++) {
                    const element = arrTexts[index];
                    const elementName = element.replace('home-feature-unique-', '');
                    if (result[element] != null)
                        obj[elementName] = utl.translateWord(result2,result[element]);

                }
                for (let index = 0; index < arrStatic.length; index++) {
                    const element = arrStatic[index];
                    const elementName = element.replace('home-feature-unique-', '');
                    if (result[element] != null)
                        obj[elementName] = utl.translateWord(result2,result[element]);

                }
                console.log("WER", obj)
                setHomeFeatureState({ ...homeFeatureState, ...obj });
            });
        });
    } catch (error) {
        
    }



    }
    return (
        <section className={`row_am unique_section ${showElement ? '' : 'd-none'}`}>
            <div className="container">
                <div className="section_title aos-init" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">

                    <h2>{homeFeatureState.title}</h2>

                    <p>{homeFeatureState.pl1} <br />{homeFeatureState.pl2}</p>
                </div>
                <div className="features_inner aos-init" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100">

                    {
                        [...Array(3)].map((x, i) => {

                            return <div className="feature_card" key={i}>
                                <div className="icons">
                                    <img src={homeFeatureState[`icon${i + 1}_url`]} alt="image" />
                                    <div className="dot_block">
                                        <span className="dot_anim"></span>
                                        <span className="dot_anim"></span>
                                        <span className="dot_anim"></span>
                                    </div>
                                </div>
                                <div className="inner_text">
                                    <h3>{homeFeatureState[`title${i + 1}`]}</h3>
                                    <p>{homeFeatureState[`dsc${i + 1}`]}
                                    </p>
                                    <a href={homeFeatureState[`link${i + 1}`]} className="btn text_btn">{homeFeatureState[`linktext${i + 1}`]} <i className="icofont-arrow-right"></i></a>
                                </div>
                            </div>
                        }
                        )



                    }


                </div>
            </div>
        </section>
    )
}

export default HomeUniqFeatures