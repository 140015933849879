import React, { useEffect, useState } from "react";
import NavItem from "./NavItem";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux'
import TopHomeWrapper from "./TopHomeWrapper";
import TopPageWrapper from "./TopPageWrapper";
import { addChangeView, addChangeLang,addChangeTranslateArr } from '../../../../store/pathActions'
import API from "../../../../modules/api";
import Utils from "../../../../modules/utils";
import * as appConsts from '../../../../store/consts'
import './TopWrapper.css';
import Languages from "./Languages";
const TopWrapper = (props) => {
    const utl = new Utils();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [itemState, setItemState] = useState(window.location.pathname)
    const [translateState, setTranslateState] = useState([])
    const is_login = useSelector((state) => state.is_login)

    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates);
    const [homeState, setHomeState] = useState(
        {
          logo_url: "./assets/images/Logo.png",
          menu_name: "main",
          menu_name_edit: false,
          menu_items: [],
    
        });
    const [menuState, setMenuState] = useState({
        isHome: false,
        Menus: []
    })

    useEffect(() => {

        loadData();
      


    }, [tranlates])

    useEffect(() => {

        fillMenu();
      


    }, [homeState])

    useEffect(() => {

        dispatch(addChangeView(itemState))
       

    }, [itemState])
    const clickMenuItem = (item) => {
        menuState.isHome = item.isHome
        setItemState(item.path)
     //   navigate(item.path)

    }
    const clickLangItem = (item) => {
        document.body.classList.remove("rtl")
        document.body.classList.remove("ltr")
        document.body.classList.add(item.dir)
        localStorage.setItem("lang", item.name)
        localStorage.setItem("dir", item.dir)
        dispatch(addChangeLang(item.name))

    }
    const fillMenu=()=>{
        try {
            tranlates.then(result => {
                dispatch(addChangeTranslateArr(result))
              //  console.log("tranlates4", result);
               // setTranslateState(result)
                let Menus = [];
                //appConsts.TopMenues.forEach(element => {
                homeState.menu_items.forEach(element => {
                    let children = [];

                    // element.childs.forEach(child => {
                      
                    //     children = [...children, { title: utl.translateWord(result, child.name), path: child.url, isSelect: false, isHome: child.isHome }];
    
                    // })


                    
                    Menus = [...Menus, { title: utl.translateWord(result, element.title), path: element.href, isSelect: false, isHome: (element.url=="/"), children: children }];
                });
               
               if(!is_login){

                   Menus = [...Menus, { title: utl.translateWord(result, "ورود"), path: "/login", isSelect: false, isHome:false, children: [] }];
                   Menus = [...Menus, { title: utl.translateWord(result, "ثبت نام"), path: "/register", isSelect: false, isHome: false, children: [] }];
               }else{

                Menus = [...Menus, { title: utl.translateWord(result, "پروفایل"), path: "/profile", isSelect: false, isHome:false, children: [] }];


               }
                // appConsts.TopMenues.forEach(element => {
                //         let children = [];
    
                //         element.childs.forEach(child => {
                          
                //             children = [...children, { title: utl.translateWord(result, child.name), path: child.url, isSelect: false, isHome: child.isHome }];
        
                //         })
    
    
                //         Menus = [...Menus, { title: utl.translateWord(result, element.name), path: element.url, isSelect: false, isHome: element.isHome, children: children }];
    
                //     })

                setMenuState({
                    ...menuState,

                    isHome: false,
                    Menus: Menus

                })


            })
        } catch (error) {

        }

    }

    const loadMenus = (obj) => {
        let arrNames = [obj.menu_name];
        (new API()).sendPostWithoutToken(`/api/links/names`, { names: arrNames }, (result) => {
            console.log("MNU", result)
            if (result[obj.menu_name] != null) {
              obj[`menu_items`] = result[obj.menu_name];
          } else {
              obj[`menu_items`] = [];
          }
    
            setHomeState({ ...homeState, ...obj });
    
        });
    }
    
    
    
    const loadData = () => {
    
        let arrNames = [];
        let arrImges = ["home-header-logo-attach-id"];
        let arrTexts = [];
        let arrStatics = ['home-header-menu_name'];
        arrNames = [...arrNames, ...arrImges];
        arrNames = [...arrNames, ...arrStatics];
      
       
        let obj = { ...homeState };
        try {
    
            (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                console.log("TRE", result)
    
                for (let index = 0; index < arrImges.length; index++) {
                    const element = arrImges[index];
                    const elementName = element.replace('home-header-', '').replace('-attach-id');
                    if (result[element] != null)
                        obj[`image${index + 1}_url`] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;
    
                }
                for (let index = 0; index < arrTexts.length; index++) {
                    const element = arrTexts[index];
                    const elementName = element.replace('home-header-', '');
                    if (result[element] != null)
                        obj[elementName] = result[element];
    
                }
                for (let index = 0; index < arrStatics.length; index++) {
    
                    const element = arrStatics[index];
    
                    const elementName = element.replace('home-header-', '');
                    if (result[element] != null)
                        obj[elementName] = result[element];
    
                }
            
               
        
                loadMenus(obj);
                //   setHomeState({ ...homeState, ...obj });
            });
    
        } catch (error) {
    
            console.log("ERR", error)
        }
    
    
    
    }




   // console.log("ALI", window.location.pathname)
    menuState.isHome = (window.location.pathname == "/")





    return (


        (menuState.isHome ? <TopHomeWrapper logo={homeState.logo_url}>
            <ul className="navbar-nav ml-auto">
                {

                    menuState.Menus.map((item, index) => {
                        return <NavItem item={item} click={() => clickMenuItem(item)} key={index} />
                    })

                }
                <Languages clicked={clickLangItem} />
            </ul>
        </TopHomeWrapper> : <TopPageWrapper logo={homeState.logo_url}>   <ul className="navbar-nav ml-auto">
            {

                menuState.Menus.map((item, index) => {
                    return <NavItem item={item} click={() => clickMenuItem(item)} key={index} />
                })

            }
            <Languages clicked={clickLangItem} />
        </ul></TopPageWrapper>)



    )
}


export default TopWrapper
