import React from "react";
import Wrapper from "../../../hoc/Wrapper/Wrapper";

const About=(props)=>{

return(
<Wrapper>
    { console.log(props)}

<h1>ABout</h1>
</Wrapper>

)


}

export default About