import React from "react";


const TopPageWrapper=(props)=>{

return(

<div className="inner_page_block">

<div className="banner_shapes">
  <div className="container">
    <span><img src="./assets/images/plus.svg" alt="image"/></span>
    <span><img src="./assets/images/polygon.svg" alt="image"/></span>
    <span><img src="./assets/images/round.svg" alt="image"/></span>
  </div>
</div>


<header className="fixed fix_style">

  <div className="container">
   
    <nav className="navbar navbar-expand-lg">
      <a className="navbar-brand" href="index.html">
        <img src={props.logo} alt="image"/>
      </a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon">
         
          <span className="toggle-wrap">
            <span className="toggle-bar"></span>
          </span>
        </span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
      {props.children}
      </div>
    </nav>
 </div>
 </header>
<div className="bread_crumb aos-init aos-animate" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
  <div className="container">
    <div className="bred_text">
      <h1>About us</h1>
      <ul>
        <li><a href="index.html">Home</a></li>
        <li><span>»</span></li>
        <li><a href="about-us.html">About us</a></li>
      </ul>
    </div>
  </div>
</div>

</div>

)

}

export default TopPageWrapper