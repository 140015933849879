import React, { useState, useEffect } from 'react'
import { connect, useDispatch ,useSelector} from 'react-redux'

import './App.css';
import LayoutSite from './layouts/Layout-site/Layout-site'
import LayoutAuth from './layouts/Layout-auth/Layout-auth'
import { addChangeLang,addChangeLlogin } from './store/pathActions'
import API from "./modules/api";

function App(props) {

  const [appState, setAppState] = useState(<LayoutSite />)
  const dispatch2 = useDispatch();

  useEffect(() => {
    let lang = "fa";
    let dir = "rtl";
    if (localStorage.getItem("lang") != null) {
      lang = localStorage.getItem("lang");
    }
    if (localStorage.getItem("dir") != null) {
      dir = localStorage.getItem("dir");
    }

    document.body.classList.remove("rtl")
    document.body.classList.remove("ltr")
    document.body.classList.add(dir)

    localStorage.setItem("lang", lang)
    dispatch2(addChangeLang(lang))
    props.onLoad()

    const token= localStorage.getItem("token")
    if(!token)
      return;

    (new API()).sendGETWithToken("/",()=>{dispatch2(addChangeLlogin(true))},(err)=>{dispatch2(addChangeLlogin(false))})
  }, [])


  useEffect(() => {
    console.log("BBBB", props.mpath)
    switch (props.mpath) {


      case "/login":
      case "/register":
        setAppState(<LayoutAuth />)
        break;
      default:
        setAppState(<LayoutSite />)
        break;
    }
    props.onLoad()

  }, [props.mpath])

  return (

    appState
  );
}

const mapStateToProps = (state) => {
  return {
    mpath: state.path,
    storeResult: state.result,
    lang: state.lang
  }
}

const mapDispatchToProps = (dispatch) => {



  return {
    onLoad: () => {
      dispatch({ type: 'CHANGEVIEW', value: window.location.pathname });
      // dispatch({ type: 'CHANGELANG',lang });
    },

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

